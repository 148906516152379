.Link,
.Link:active,
.Link:visited {
  font-size: 0.8rem;
  text-decoration: none;
  color: rgb(151, 15, 22);
  font-weight: bold;
  cursor: pointer;
}
.Link:hover {
  text-decoration: underline;
}
