.Link,
.Link:active,
.Link:visited {
  font-size: 0.8rem;
  text-decoration: none;
  color: rgb(151, 15, 22);
  font-weight: bold;
  cursor: pointer;
}
.Link:hover {
  text-decoration: underline;
}
.teamMembers {
  border-bottom: 1px solid #ccc;
  padding: 4px;
  cursor: pointer;
}
.copyLink {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 1px 7px;
  font-size: 0.8rem;
  margin: 0 5px;
  color: #777;
  text-decoration: none;
}
.copyLink:hover {
  color: #852424;
  border: 1px solid #852424;
}
