.header {
  color: #b72b2e;
  font-weight: bold;
  font-style: italic;
  margin-top: 15px;
}
.row {
  padding: 3px;
  cursor: pointer;
}
.row:hover {
  text-decoration: underline;
}
