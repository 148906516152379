.Link,
.Link:active,
.Link:visited {
  padding: 20px;
  cursor: pointer;
  text-align: center;
}
.Link:hover {
  background: #f8f4f4;
}
.Text {
  text-decoration: none;
  color: #777;
  font-weight: 500;
  font-size: 1rem;
}
.breadcrumb {
  color: purple;
}
