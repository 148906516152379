.heading {
  font-size: 14px;
  padding: 3px;
  font-weight: bold;
  /* background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; */
}
.listItem {
  padding-top: 0;
  padding-bottom: 0;
  background-color: pink;
}
.listItemText {
  margin-left: 7px;
  font-size: 14px;
}
figure > img {
  height: auto;
  max-width: 100%;
}
.teacherFeedback,
.studentComment {
  color: #8f6513;
  font-size: 0.8rem;
  background: #faf9eb;
  /* border: 1px solid #dfddbf; */
  padding: 3px;
  margin: 8px 0;
}
.studentComment {
  color: rgb(75, 117, 115);
  background: #edf5f4;
}
.feedbackHeading {
  font-size: 0.7rem;
  font-weight: bold;
}
.openFeedback {
  color: #0b0035;
  font-size: 0.8rem;
  background: #daebff;
  /* border: 1px solid #dfddbf; */
  padding: 3px;
  margin: 8px 0;
}
.openFeedbackHeading {
  font-size: 0.7rem;
  font-weight: bold;
}
.txtFeedback {
  font-size: 0.8rem;
  background: #fff;
  border: 1px solid #ccc;
  padding: 3px;
  margin: 8px 0;
  border-radius: 3px;
}

.ckWrapper {
  animation: fadeOut 7s forwards;
  animation-delay: 0s;
  border: 6px solid #ffffff;
}

@keyframes fadeOut {
  from {
    border: 6px solid rgb(64, 163, 255);
  }
  to {
    border: 6px solid #ffffff;
  }
}
