.Link,
.Link:active,
.Link:visited {
  font-size: 0.8rem;
  text-decoration: none;
  color: rgb(151, 15, 22);
  font-weight: bold;
  cursor: pointer;
}
.Link:hover {
  text-decoration: underline;
}
.pending {
  background: #fff;
  margin: 20px 0;
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
  font-style: italic;
  text-decoration: underline;
}

.purchaseContainer {
  padding: 5px;
  width: 60%;
  border: 1px solid #ddd;
  border-radius: 3px;
  background: #fff;
  margin-top: 30px;
}
table.tblPurchase {
  border: none;
  border-collapse: collapse;
  width: 100%;
}

table.tblPurchase tr td {
  font-size: 12px;
}
